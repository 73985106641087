<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">网上课堂</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">课程管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="课程名称" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item label="课程分类" prop="classify">
        <el-select
          v-model="queryModel.classify"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:150px"
        >
          <el-option
            v-for="item in classifyList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="难度" prop="level">
        <el-select
          v-model="queryModel.level"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:150px"
        >
          <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="sortNo" label="序号" sortable="custom" width="80"></el-table-column>
      <el-table-column prop="coverImageUrl" label="封面图片" sortable="custom" width="180">
        <template slot-scope="{row}">
          <el-avatar
            shape="square"
            :size="48"
            :src="row.coverImageUrl+'?x-oss-process=image/resize,m_fill,w_64,h_64'"
            :key="row.id"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="课程名称" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="desc" label="描述" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="classifyN" label="课程分类" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="levelN" label="难度" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="top" label="是否置顶" sortable="custom" width="180">
        <template slot-scope="{row}">
            <el-switch  v-model="row.top" active-color="#13ce66" inactive-color="#ff4949"
            @change="updateTop(row)"
            ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" sortable="custom" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="300">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="success" @click="handleChapterManage(row)">章节管理</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <courseInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></courseInfo-detail>
    <chapterInfo-list
      v-if="showChapterListModal"
      :businessKey="businessKey"
      :title="'章节管理'"
      @close="onChapterListModalClose"
    ></chapterInfo-list>
  </div>
</template>
<script>
import Constant from "@/constant";
import CourseInfoDetail from "./courseInfo-detail";
import ChapterInfoList from "./chapterInfo-list";

import courseInfoApi from "@/api/onlineClass/courseInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        name: "",
        classify: "",
        level: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      classifyList: [],
      levelList: [],
      showChapterListModal: false
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.goodsId != null) {
        formData.append("goodsId", self.queryModel.goodsId);
      }

      if (self.queryModel.classify != null) {
        formData.append("classify", self.queryModel.classify);
      }

      if (self.queryModel.level != null) {
        formData.append("level", self.queryModel.level);
      }

      courseInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          courseInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        courseInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleChapterManage(row) {
      this.businessKey = row.id;
      this.showChapterListModal = true;
    },
    onChapterListModalClose() {
      this.showChapterListModal = false;
    },
    updateTop(row) {
      courseInfoApi.update(row);
    }
  },
  mounted: function () {
    this.changePage(1);
  },
  created: function () {
    dataDictionaryApi
      .findByCatalogName({ catalogName: "网上课程难度" })
      .then((response) => {
        var jsonData = response.data;
        this.levelList = jsonData.data;
      });

    dataDictionaryApi
      .findByCatalogName({ catalogName: "网上课程分类" })
      .then((response) => {
        var jsonData = response.data;
        this.classifyList = jsonData.data;
      });
  },
  components: {
    "courseInfo-detail": CourseInfoDetail,
    "chapterInfo-list": ChapterInfoList
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>