import request from '@/utils/request'
import constant from '@/constant'

function edit(id){
  return request.get(constant.serverUrl + "/chapterInfo/edit/" + id);
}

function update(formModel){  
  return request.post(constant.serverUrl + "/chapterInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  edit,update
}