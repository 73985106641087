<template>
  <div>
    <el-dialog
      width="80%"
      :visible.sync="showDialog"
      :title="title"
      :modal-append-to-body="true"
      :append-to-body="true"
      style="text-align:left;"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <div class="user-panel" v-loading="loading">
        <el-form ref="form" :model="formModel">
          <el-form-item label="课程名称" prop="name">
            <span v-html="formModel.course.name"></span>
          </el-form-item>
          <el-table :data="formModel.chapterList" :height="400">
            <el-table-column type="index" label="序号" width="55"></el-table-column>
            <el-table-column prop="name" label="章节名称">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'chapterList.' + scope.$index + '.name'"
                  :rules="ruleValidate['name']"
                >
                  <el-input placeholder="章节名称" style="width:250px" v-model="scope.row.name"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="videoUrl" label="阿里云视频编号">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'chapterList.' + scope.$index + '.videoUrl'"
                  :rules="ruleValidate['videoUrl']"
                >
                  <el-input placeholder="阿里云视频编号" style="width:250px;" v-model="scope.row.videoUrl"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'chapterList.' + scope.$index + '.remark'"
                >
                  <el-input placeholder="备注" style="width:200px" v-model="scope.row.remark"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="{row}">
                <el-form-item>
                  <i class="el-icon-delete my-font" @click="handleDelete(row)"></i>
                  <i class="el-icon-top my-font" @click="handleUp(row)"></i>
                  <i class="el-icon-bottom my-font" @click="handleDown(row)"></i>
                  <i class="el-icon-document-add my-font" @click="handleInsert(row)"></i>
                </el-form-item>
              </template>
            </el-table-column>
            <div slot="empty">
              <el-button icon="el-icon-circle-plus-outline" :plain="true" @click="handleAdd()">新增</el-button>
            </div>
          </el-table>
        </el-form>
        <div class="el-table__empty-block" v-if="formModel.chapterList.length!=0">
          <el-button icon="el-icon-circle-plus-outline" @click="handleAdd()">新增</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import chapterInfoApi from "@/api/onlineClass/chapterInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        videoUrl: [{ required: true, message: "视频编号不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      formModel: {
        course:{},
        chapterList: [],
        removeList: []
      },
      submitting: false
    };
  },
  created() {
    var self = this;
  },
  computed: {
    // stationSortList() {
    //   return this.formModel.stationList.sort((a,b) => {
    //     return a.sortNo - b.sortNo
    //   });
    // }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {          
          self.loading = true;

          var chapterList = this.formModel.chapterList;

          for(var i=0;i<chapterList.length;i++){
            chapterList[i].courseId = self.businessKey;
            chapterList[i].sortNo = i+1;
          }

          chapterInfoApi.update(self.formModel).then(function(response) {
            self.loading = false;
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });
              self.$emit("close", {
                result: true,
                data: jsonData.data
              });
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", {
                result: false
              });
            }
          });
        }
      });
    },
    handleAdd() {
      this.formModel.chapterList.push({
        id: "",
        courseId: this.businessKey,
        sortNo: this.formModel.chapterList.length + 1,
        name: "",
        videoUrl: "",
        remark: ""
      });
    },
    handleDelete(row) {
      var chapterList = this.formModel.chapterList;

      chapterList.splice(chapterList.indexOf(row), 1);

      this.formModel.removeList.push(row);
    },
    handleUp(row) {
      var chapterList = this.formModel.chapterList;
      var index = chapterList.indexOf(row);

      if(index>0){
        chapterList.splice(index, 1);
        chapterList.splice(index-1,0,row);
      }
    },
    handleDown(row) {
      var chapterList = this.formModel.chapterList;
      var index = chapterList.indexOf(row);

      if(index<chapterList.length-1){
        chapterList.splice(index, 1);
        chapterList.splice(index+1,0,row);
      }
    },
    handleInsert(row) {
      var chapterList = this.formModel.chapterList;
      var index = chapterList.indexOf(row);

      if(index>=0){
        chapterList.splice(index+1,0,{
          id: "",
          courseId: this.businessKey,
          sortNo: index + 2,
          name: "",
          videoUrl: "",
          remark: ""
        });
      }
    }
  },
  async mounted() {
    var self = this;

    self.loading = true;

    chapterInfoApi.edit(self.businessKey)
      .then(response => {
        self.loading = false;

        var jsonData = response.data;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}

.amap-demo {
  width: 100%;
  height: 400px;
}

.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}
</style>