
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    width="70%"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-row>
          <el-col :span="12">
            <el-form-item label="课程名称" prop="goodsId">
              <el-input v-model="formModel.name" placeholder="请输入课程名称" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述" prop="desc">
              <el-input v-model="formModel.desc" placeholder="请输入课程描述" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="课程分类" prop="classify">
              <el-select
                v-model="formModel.classify"
                filterable
                placeholder="请选择"
                style="width:300px"
              >
                <el-option
                  v-for="item in classifyList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="难度" prop="level">
              <el-select
                v-model="formModel.level"
                filterable
                placeholder="请选择"
                style="width:300px"
              >
                <el-option
                  v-for="item in levelList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>        
        <el-row>
          <el-col :span="12">
            <el-form-item label="价格(元)" prop="level">
              <el-input-number v-model="formModel.cost" :precision="2" style="width:300px;" controls-position="right"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="序号" prop="sortNo">
              <el-input-number v-model="formModel.sortNo" :precision="0" style="width:300px;"></el-input-number>
            </el-form-item>
          </el-col> 
        </el-row>
        <el-form-item label="是否置顶" prop="top">
          <el-switch
            v-model="formModel.top"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="商品图片" prop="pic">
          <upload-image v-model="formModel.pic" sub-folder="onlineClass" :size="128"></upload-image>
        </el-form-item>
        <el-form-item label="封面" prop="coverImageUrl">
          <upload-image v-model="formModel.coverImageUrl" sub-folder="onlineClass" :size="300"></upload-image>
        </el-form-item>
        <el-form-item label="课程介绍" prop="introduction">
          <!-- <el-input v-model="formModel.introduction" placeholder="请输入课程介绍" style="width:300px"></el-input> -->
          <mce-editor
            ref="editor"
            uploadName="upfile"
            v-model="formModel.introduction"
            :url="uploadUrl"
            :config="editorConfig"
          ></mce-editor>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import courseInfoApi from "@/api/onlineClass/courseInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import MceEditor from "@/components/Tinymce";
import UploadImage from "@/components/UploadImage";
import { getToken } from "@/utils/auth";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        name: [
          { required: true, message: "课程编号不能为空", trigger: "blur" },
        ],
        classify: [
          { required: true, message: "课程分类不能为空", trigger: "blur" },
        ],
        level: [{ required: true, message: "难度不能为空", trigger: "blur" }],
        cost: [
          { required: true, message: "价格不能为空", trigger: "blur" },
        ],
        coverImageUrl: [
          { required: true, message: "封面图片不能为空", trigger: "blur" }
        ]
      },
      formModel: {},
      showDialog: true,
      loading: false,
      submitting: false,
      editorConfig: {
        height: 300,
        width: 600
      },
      uploadUrl: Constant.serverUrl + "/tinymce/upload?token=" + getToken(),      
      classifyList: [],
      levelList: []
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return courseInfoApi.add(self.formModel);
            } else {
              return courseInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return courseInfoApi.create();
      } else {
        return courseInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          
          if (self.formModel.introduction != null) {
            self.$refs.editor.setContent(self.formModel.introduction);
          }          
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  created: function () {
    dataDictionaryApi
      .findByCatalogName({ catalogName: "网上课程难度" })
      .then((response) => {
        var jsonData = response.data;
        this.levelList = jsonData.data;
      });

    dataDictionaryApi
      .findByCatalogName({ catalogName: "网上课程分类" })
      .then((response) => {
        var jsonData = response.data;
        this.classifyList = jsonData.data;
      });
  },
  components: {
    "mce-editor": MceEditor,
    "upload-image": UploadImage
  }
};
</script>